<template>
  <div class="">
    <PageTitle
      title="AI 熱門預約時段預測"
      btnPlain
      btn="說明"
      @btnClick="modal.explanation = true"
    />

    <div class="mb-[30px]">
      <BaseElSelect v-model="search.range" value-key="label" @change="onRangeChange">
        <BaseElSelectOption
          v-for="option in dateRangeOptions"
          :key="option.label"
          :value="option"
          :label="option.label"
        />
      </BaseElSelect>
    </div>

    <div v-loading="loading" class="flex w-full bg-white" style="padding: 40px; border-radius: 4px;">
      <div class="flex flex-col justify-evenly mr-[10px]" style="padding-bottom: 32px;">
        <p>凌晨</p>
        <p />
        <p>上午</p>
        <p />
        <p>下午</p>
        <p />
        <p>晚上</p>
      </div>

      <div class="flex w-full" style="gap: 2px; margin-right: 40px;">
        <div
          v-for="(dayData, index) in displayData"
          :key="index"
          class="flex flex-col w-full items-center"
        >
          <PeriodHeatmap
            class="w-full"
            :data="dayPeriodData(dayData)"
          />
          <p>{{ get(weeksLabel, index) }}</p>
        </div>
      </div>

      <div class="flex-shrink-0" style="padding-bottom: 32px;">
        <div class="h-full flex flex-col">
          <div
            v-for="(stage, index) in heatColor"
            :key="stage.color"
            class="flex items-center h-full"
            style="gap: 10px"
          >
            <div
              class="h-full overflow-hidden"
              :style="{
                backgroundColor: stage.color,
                width: '12px',
                borderTopLeftRadius: index === 0 ? '8px' : '0',
                borderTopRightRadius: index === 0 ? '8px' : '0',
                borderBottomLeftRadius: index === heatColor.length - 1 ? '8px' : '0',
                borderBottomRightRadius: index === heatColor.length - 1 ? '8px' : '0',
              }"
            />
            <p class="text-sub flex-shrink-0">{{ stage.text }}</p>
          </div>
        </div>
      </div>
    </div>

    <ExplanationModal
      v-if="modal.explanation"
      @close="modal.explanation = false"
    />

    <BaseDialog
      v-if="modal.noIndustry"
      title="提示"
      hideCancel
      @close="modal.noIndustry = false"
      @confirm="modal.noIndustry = false"
    >
      <div style="margin-bottom: 42px">
        請先至分店設定編輯分店所屬產業類別，即可顯示所屬產業預測資料
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import PeriodHeatmap from './components/PeriodHeatmap.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { GetBeautyAIAptSchedulePrediction } from '@/api/beautyAI'
import dayjs from '@/lib/dayjs'
import { formatDate } from '@/utils/date'
import { useShop } from '@/use/shop'
import { map, get, fill, find } from 'lodash'
import { weeksOptions } from '@/config/date'
import ExplanationModal from './components/ExplanationModal.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'PopularBookingTimePrediction',
  components: { PeriodHeatmap, PageTitle, ExplanationModal, BaseDialog },
  setup (props) {
    const { shopId, shopIndustry } = useShop()
    const data = ref([])
    const loading = ref(false)
    const modal = reactive({
      explanation: false,
      noIndustry: false,
    })
    const search = reactive({
      range: null,
    })

    const generateDateRangeOptions = (setSearchRange = false) => {
      // JavaScript code to list the next 12 weeks as options, from Monday to Sunday, including the current week if today is Monday

      // start is  current year 01/08
      const start = new Date(new Date().getFullYear(), 0, 8)

      // Modified function to get the next Monday or return today if it's already Monday
      function getNextMonday (date) {
        const nextMonday = new Date(date)
        // Check if today is Monday (getDay() returns 1 for Monday)
        if (date.getDay() === 1) {
          return nextMonday // Return today if it's Monday
        } else {
          // Calculate the next Monday
          nextMonday.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7 || 7))
          return nextMonday
        }
      }

      // Array to hold the next 12 weeks as options
      const next12WeeksOptions = []

      // Calculate the date of the next Monday or today if it's already Monday
      const nextMonday = getNextMonday(start)

      // Loop to calculate each week's Monday to Sunday range
      for (let i = 0; i < 51; i++) {
        // Calculate the Sunday of the current week (6 days after Monday)
        const currentSunday = new Date(nextMonday)
        currentSunday.setDate(nextMonday.getDate() + 6)

        // Format the dates and add the range to the array
        next12WeeksOptions.push([formatDate(nextMonday, 'YYYY/MM/DD'), formatDate(currentSunday, 'YYYY/MM/DD')])

        // Calculate the next Monday (7 days after the current Monday)
        nextMonday.setDate(nextMonday.getDate() + 7)
      }

      const options = convertToArrayOfObjects(next12WeeksOptions)

      if (setSearchRange) {
        // 設置 search.range 為當下時間符合的週範圍選項裡
        const same = find(options, (option) => {
          const start = dayjs(option.value[0])
          const end = dayjs(option.value[1])
          return dayjs().isBetween(start, end, 'day') || dayjs().isSame(start, 'day') || dayjs().isSame(end, 'day')
        })
        search.range = same
        onRangeChange(same)
      }

      return options
    }
    const dateRangeOptions = computed(() => {
      return generateDateRangeOptions()
    })

    const convertToArrayOfObjects = (options) => {
      const result = {}
      options.forEach((option, index) => {
        const key = `day${index + 1}`
        result[key] = {
          label: `${option[0]} ~ ${option[1]}`,
          value: option,
        }
      })
      return result
    }

    const groupByDay = (dataArray = []) => {
      const groupedByDay = {}

      dataArray.forEach(item => {
        // Extract the date part from the timestamp
        const dateKey = formatDate(item.hourTimestamp, 'YYYY/MM/DD')

        // Check if the key exists, if not initialize an empty array
        if (!groupedByDay[dateKey]) {
          groupedByDay[dateKey] = []
        }

        // Push the current item to the corresponding array
        groupedByDay[dateKey].push(item)
      })

      // Convert the object of arrays into an array of arrays
      return Object.values(groupedByDay)
    }

    const displayData = computed(() => {
      if (!data.value.length) return [[], [], [], [], [], [], []]
      return groupByDay(data.value)
    })

    const dayPeriodData = computed(() => {
      return (data) => {
        if (!data || !data.length) return fill(Array(24), 1)
        return map(data, 'popularityLevel')
      }
    })

    const onRangeChange = async () => {
      if (!shopIndustry.value) {
        modal.noIndustry = true
        return
      }
      if (!get(search.range, 'value')) return
      if (loading.value) return
      loading.value = true
      // Get the data for the selected date range
      const [res, err] = await GetBeautyAIAptSchedulePrediction({
        shopId: shopId.value,
        startAt: dayjs(search.range.value[0]).startOf('day').toDate(),
        endAt: dayjs(search.range.value[1]).endOf('day').toDate(),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      // data.value = res.result
      // 根據 hourTimestamp 排序
      const dataPlaceHolder = res.result
      dataPlaceHolder.sort((a, b) => new Date(a.hourTimestamp) - new Date(b.hourTimestamp))
      data.value = map(dataPlaceHolder, (i) => {
        return {
          hourTimestamp: formatDate(i.hourTimestamp, 'YYYY/MM/DD HH:mm:ss'),
          popularityLevel: i.popularityLevel,
        }
      })
    }

    const weeksLabel = {
      0: '星期一',
      1: '星期二',
      2: '星期三',
      3: '星期四',
      4: '星期五',
      5: '星期六',
      6: '星期日',
    }

    const heatColor = [
      { color: '#4175f5', text: '通常很多顧客' },
      { color: '#6791f7', text: '通常較多顧客' },
      { color: '#9fb9f9', text: '偶爾有顧客' },
      { color: '#c6d6fc', text: '很少有顧客' },
      { color: '#ecf2fe', text: '通常無顧客' },
    ]

    onBeforeMount(() => {
      if (!shopIndustry.value) modal.noIndustry = true
      generateDateRangeOptions(true)
    })

    return {
      data,
      search,
      dateRangeOptions,
      onRangeChange,
      displayData,
      dayPeriodData,
      weeksOptions,
      weeksLabel,
      get,
      loading,
      heatColor,
      modal,
      shopIndustry,
    }
  },
})
</script>

<style lang="postcss" scoped>
</style>
